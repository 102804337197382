form,
.teamup-form {
 label {
   color: var(--teamup-grey);
   letter-spacing: px2rem(0.28);
   font-size: var(--font-size--subtitle);
   margin-bottom: 0.25rem;
 }
}

.teamup-form {
  &__input-icon {
    position: relative;

    --icon-width: #{px2rem(40)};
    --icon-image: '\e918';

    &::before {
      content: var(--icon-image);
      font-family: icomoon;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      justify-content: center;
      width: var(--icon-width);
      color: var(--teamup-white);
    }

    input {
      background-image: linear-gradient(var(--teamup-light-grey), var(--teamup-light-grey));
      background-size: var(--icon-width) 100%;
      background-position: right center;
      background-repeat: no-repeat;
      appearance: none;
    }

    &--calendar {
      --icon-image: '\e91b';
    }
  }
}

.form {
  &-check {
    &-wrapper {
      margin-top: px2rem(20);
      display: flex;
      justify-content: space-between;
      width: 100%;

      .form-radio + .form-radio {
        margin-top: 0;
      }

      .form-check + .form-check {
        margin-left: px2rem(15);
      }

      & + div > span {
        margin-top: px2rem(15);
        color: var(--teamup-turquoise);
      }
    }
  }
}

.teamup-textarea {
  border-radius: px2rem(20);
  color: $input-color;
  background-color: var(--teamup-border-color);
  padding: px2rem(15) px2rem(20);
}