.teamup-timeline {
  display: grid;
  grid-template-columns: px2rem(35) 1fr;
  grid-template-areas: '. timeline';

  & > :first-child {
    grid-area: timeline;
    counter-reset: counter;
  }

  .card {
    --bs-card-spacer-y: #{px2rem(20)};

    border-top-left-radius: 0;
    display: grid;
    grid-gap: px2rem(20);
    grid-template-columns: px2rem(52) auto;
    grid-template-areas: 'card-body card-header';


    &-header {
      grid-area: card-header;

      img {
        width: px2rem(20);
        height: px2rem(20);
        object-fit: cover;
        border-radius: 100%;
      }

      & + .card-body {
        padding-top: var(--bs-card-spacer-y);
      }
    }

    &-body {
      grid-area: card-body;

      img {
        width: px2rem(52);
        height: px2rem(52);
        object-fit: cover;
        border-radius: px2rem(10);
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: -2rem;
      color: var(--teamup-blue);
      font-weight: 700;
      top: calc(50% - 21px);
    }

    &:not(.card--disabled) {
      counter-increment: counter;

      &::before {
        content: counter(counter, decimal-leading-zero);
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 2rem);
        left: -1.5rem;
        background-color: var(--teamup-light-grey);
        top: 3.75rem;
      }
    }

    &--disabled {
      &::before {
        content: '\e909';
        font-family: icomoon;
        color: var(--teamup-blue-2);
        font-size: 1.25rem;
        top: calc(50% - 25px);
        left: -2.1rem;
      }
    }

    .icon-stats {
      font-size: 1.15rem;
    }
  }

  & + .btn {
    margin-top: px2rem(40);
    margin-bottom: px2rem(25);
  }
}