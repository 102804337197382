.card {
  position: relative;
  box-shadow: var(--teamup-box-shadow);
  width: 100%;

  --bs-card-border-width: 0;
  --bs-card-border-radius: var(--teamup-border);
  --bs-card-color: var(--teamup-purple);
  --bs-card-cap-bg: transaprent;
  --bs-card-spacer-y: #{px2rem(20)};
  --bs-card-spacer-x: #{px2rem(20)};
  --bs-card-cap-padding-x: var(--bs-card-spacer-x);
  --bs-card-cap-padding-y: var(--bs-card-spacer-y);

  &-header {
    --bs-card-cap-padding-y: 1.25rem;

    padding-bottom: px2rem(14);
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icons {
      display: flex;
      align-items: center;

      * + * {
        margin-left: px2rem(15);
      }

      &--big {
        font-size: var(--font-size--button);
      }

      &--with-parting-line {
        [class^="icon-"] {
          margin-right: .75rem;

          &:only-child {
            margin-right: 0;
          }

          & + [class^="icon-"] {
            position: relative;
            margin-left: .75rem;

            &::after {
              content: '';
              position: absolute;
              width: 1px;
              height: calc( 100% + #{px2rem(10)});
              background-color: var(--teamup-border-color);
              left: -.75rem;
              top: -0.25rem;
            }
          }
        }
      }
    }

    a {
      text-decoration: none;
    }

    & + .card-body {
      padding-top: 0.75rem;
    }
  }

  &-title {
    font-size: px2rem(18);
    margin-bottom: 0;

    & + .card-subtitle {
      margin-top: 0.15rem;
    }

    & + * {
      margin-left: auto;
    }
  }

  & >*:only-child.card-header {
    --bs-card-cap-padding-y: 0.75rem;
  }


  &--disabled {
    pointer-events: none;

    --bs-card-border-width: 2px;
    --bs-card-border-color: #AEB7C6;

    box-shadow: none;
    background-color: transparent;

    .icon-eye {
      color: var(--bs-card-border-color);
    }

    .card {
      &-header {
        padding-right: calc(var(--bs-card-spacer-x) - 1px);
      }

      &-body {
        padding-left: calc(var(--bs-card-spacer-x) - 1px);
      }
    }
  }

  &-subtitle {
    display: block;
    font-size: var(--font-size--subtitle);
    color: var(--teamup-light-grey);
    line-height: 1.2;
  }

  &-subtitle--blue {
    color: var(--teamup-blue);
  }

  &--has-body-button {
    .card-body {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'content icons';
      grid-gap: 1rem;
      align-items: center;

      & > :first-child {
        grid-area: content;
      }

      & :nth-child(2) {
        grid-area: icons;
        display: flex;
        align-items: center;

        * + * {
          margin-left: px2rem(15);
        }
      }
    }
  }

  &-body {
    background-image: linear-gradient(var(--teamup-border-color), var(--teamup-border-color));
    background-size: calc(100% - 40px) 1px;
    background-repeat: no-repeat;
    background-position: top center;

    &:first-child{
      margin-top: 0;
    }

    &--grid-3 {
      display: grid;
      grid-template-columns: px2rem(52) 1fr auto;
      grid-gap: px2rem(16.5);
      position: relative;
    }

    &__title {
      color: var(--teamup-blue-6);
      display: block;
      margin-top: px2rem(20);
      margin-bottom: px2rem(10);
    }

    &__title--evaluation {
      color: var(--teamup-blue-6);
      display: block;
      margin-top: px2rem(13);
      margin-bottom: 0;
    }

    p {
      line-height: 1.3;
    }
  }

  &-link {
    @include btn-instruction;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--blue {
    --bs-card-bg: var(--teamup-dark-blue);
    --bs-card-color: var(--teamup-white);

    .card-title {
      color: var(--bs-card-color);
    }

    form,
    .teamup-form {
      label {
        color: var(--teamup-blue);
        margin-bottom: px2rem(8);
      }

      input,
      input:focus {
        color: var(--teamup-blue-2);
      }
    }
  }

  &--title-center {
    .card {
      &-header {
        justify-content: center;
        padding-bottom: #{px2rem(20)};
      }

      &-body {
        padding-top: 0;
        background-image: none;
      }
    }
  }

  &--img {
    --bs-card-spacer-x: 0;
    --bs-card-spacer-y: 0;

    overflow: hidden;

    img {
      & + * {
        margin-top: px2rem(20);
      }
    }
  }

  &--description {
    --bs-card-bg: var(--teamup-blue);
    --bs-card-color: var(--teamup-white);

    .card {
      &-header {
        --bs-card-cap-padding-y: 0;

        padding-top: var(--bs-card-spacer-y);
      }

      &-body {
        padding-top: 1rem;
        background-image: none;
      }
    }

    .card-title {
      color: var(--teamup-white);
    }

    .card-body {
      &__content {
        & >:first-child {
          display: grid;
          grid-template-columns: px2rem(78) 1fr;
          grid-gap: px2rem(22);
        }

        & + p {
          margin-top: 1rem;
        }
      }
    }

    img {
      width: 100%;
      border-radius: px2rem(20);
      height: px2rem(78);
      object-fit: cover;
    }

    p {
      font-size: px2rem(14.5);
    }

    &-info {
      font-size: var(--font-size--labeling_1);
    }
  }

  &--header-blue {
    --bs-card-cap-bg: var(--teamup-blue);

    .card-header {
      border-top-left-radius: var(--teamup-border);
      border-top-right-radius: var(--teamup-border);
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .card-title {
      color: var(--teamup-white);
    }

    [class^="icon-"] {
      color: var(--teamup-white);
      font-size: var(--font-size--button);
      margin-right: .75rem;

      &:only-child {
        margin-right: 0;
      }

      & + [class^="icon-"] {
        position: relative;
        margin-left: .75rem;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: calc( 100% + #{px2rem(10)});
          background-color: var(--teamup-white);
          left: -.75rem;
          top: -0.35rem;
        }
      }
    }

    .icon-drag,
    .icon-edit {
      transform: translateY(0.15rem);
      display: inline-block;
    }
  }

  &--row-reverse {
    flex-direction: row-reverse;
    align-items: center;

    .card-header {
      flex: 1 1 auto;
      padding-top: px2rem(17);
      padding-bottom: px2rem(17);
    }

    .card-body {
      flex: 0;
      background-size: 2px #{px2rem(37)};
      background-repeat: no-repeat;
      background-position: center right;
      background-image: linear-gradient(var(--teamup-light-grey), var(--teamup-light-grey));

      //
      padding-top: var(--bs-card-spacer-y);
      color: var(--teamup-blue);
      font-size: var(--font-size--title-screen_2);
      font-weight: bold;
    }
  }

  &--text-grey {
    --bs-card-color: var(--teamup-grey-2);
  }

  &--without-line {
    .card-body {
      padding-top: 0;
      background-image: none;
    }
  }

  &--header-small-headline {
    .card-title {
      font-size: var(--font-size--subtitle);
    }
  }

  &--long-line-left {
    .card-header {
      background-size: 2px calc(100% - 72px);
      background-repeat: no-repeat;
      background-position: center left;
      background-image: linear-gradient(var(--teamup-light-grey), var(--teamup-light-grey));

      & + .card-body {
        background-image: none;
      }
    }
  }

  &--with-chart{
    height: 360px;
    justify-content: center;
    margin-bottom: px2rem(20);

    &__help{
      position: absolute;
      font-size: 18px;
      top: 5%;
      right: 5%;
      z-index: 1;
    }
  }

  &--gradient-border{
    background: linear-gradient(white, white) padding-box,
    linear-gradient(135deg, rgb(80 208 175 / 100%) 35%, rgb(30 180 255 / 100%) 100%) border-box;
    border: 1px solid transparent;
  }

  &__task{
    &-head {
      position: relative;
      z-index: 1;
      height: 120px;

      &::before {
        background: linear-gradient(108deg, var(--teamup-turquoise) 0%, var(--teamup-blue) 100%);
        border-top-left-radius: var(--teamup-border);
        border-top-right-radius: var(--teamup-border);
        opacity: 0.3;
        content: "";
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: -1;
      }

      &--full {
        position: relative;
        z-index: 1;
        height: 250px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;


        &::before {
          background-image: url("/dist/images/intro_full/bg_fullTask.svg");
          background-repeat: no-repeat;
          background-position: center;
          content: "";
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          z-index: -1;
        }
      }
    }

    &-body{
      --bs-card-spacer-y: 15px;
      --bs-card-spacer-x: 20px;

      &--name, &--btn{
        font-size: var(--font-size--instruction);
        margin-bottom: 0;
      }

      &--start{
        font-size: var(--font-size--instruction);
        color: var(--teamup-blue);
        margin: auto 0;
        font-weight: normal;
      }

      &--duration{
        font-size: var(--font-size--subtitle);
        font-weight: bold;
        color: var(--teamup-blue-2);
      }

      &--separator{
        border-bottom: 1px solid var(--teamup-grey-4);
        width: 100%;
        margin: px2rem(13) auto;
      }

      &--full {
        &-name {
          font-size: px2rem(18);
          letter-spacing: 0.36px;
          line-height: 22px;
          margin-bottom: px2rem(20);
          color: var(--teamup-blue-2);
        }

        &-text{
          font-size: px2rem(14);
          color: var(--teamup-purple);
          letter-spacing: 0.29px;
          line-height: 19px;
        }
      }
    }

    &-footer{
      display: grid;
      grid-template-columns: 50px 1fr;
      grid-template-rows: 50px;
      grid-gap: 0 25px;

      .btn--close {
        color: var(--teamup-blue);

        --bs-btn-border-color: var(--teamup-blue);

        width: 50px;
        height: 50px;
        font-size: 3rem;
      }
    }

    &-icon {
      margin: auto;
      display: block;
      max-height: 120px;

      &--full {
        height: 220px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &-accordion{
      --bs-accordion-color: var(--teamup-purple);
      --bs-accordion-border-width: 0;
      --bs-accordion-active-bg: none;
      --bs-accordion-active-color: var(--teamup-grey-2);
      --bs-accordion-btn-color: var(--teamup-grey-2);
      --bs-accordion-btn-icon: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.682' height='8.669' viewBox='0 0 14.682 8.669'%3E%3Cg id='Iconly_Bold_Arrow---Left-Circle' data-name='Iconly/Bold/Arrow---Left-Circle' transform='translate(0 8.669) rotate(-90)'%3E%3Cg id='Arrow---Left-Circle' transform='translate(0 0)'%3E%3Cpath id='Pfad_170' data-name='Pfad 170' d='M8.293.391a1.281,1.281,0,0,0-1.843,0h0L.382,6.424a1.3,1.3,0,0,0,0,1.843h0L6.45,14.3a1.223,1.223,0,0,0,.9.382,1.3,1.3,0,0,0,.921-2.225h0L3.147,7.345,8.275,2.234A1.3,1.3,0,0,0,8.293.391Z' transform='translate(0 0)' fill='%237f8ca5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.682' height='8.669' viewBox='0 0 14.682 8.669'%3E%3Cg id='Iconly_Bold_Arrow---Left-Circle' data-name='Iconly/Bold/Arrow---Left-Circle' transform='translate(0 8.669) rotate(-90)'%3E%3Cg id='Arrow---Left-Circle' transform='translate(0 0)'%3E%3Cpath id='Pfad_170' data-name='Pfad 170' d='M8.293.391a1.281,1.281,0,0,0-1.843,0h0L.382,6.424a1.3,1.3,0,0,0,0,1.843h0L6.45,14.3a1.223,1.223,0,0,0,.9.382,1.3,1.3,0,0,0,.921-2.225h0L3.147,7.345,8.275,2.234A1.3,1.3,0,0,0,8.293.391Z' transform='translate(0 0)' fill='%237f8ca5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      --bs-accordion-btn-icon-width: 1rem;
      --bs-accordion-btn-padding-x: 0;
      --bs-accordion-btn-padding-y: 0;
      --bs-accordion-body-padding-x: 0;
      --bs-accordion-body-padding-y: 15px;

      .accordion-button::after {
        width: 15px;
        height: 8px;
        background-size: contain;
      }

    }

    &-flags {
      display: flex;
      margin-top: px2rem(15);

      .icon-flag {
        color: var(--teamup-grey-2);
        font-size: px2rem(13);
        margin: auto 0;
      }
    }

    &-flag {
      background: var(--teamup-light-grey);
      color: var(--teamup-white);
      padding: px2rem(9) px2rem(20);
      border-radius: px2rem(18);
      line-height: px2rem(17);
      letter-spacing: px2rem(0.28);
      margin-left: px2rem(10);
    }
  }

  & + .container {
    margin-top: px2rem(20);
  }
}

* + .cards-container,
* + .card,
.card + .accordion--card {
  margin-top: px2rem(20);
}