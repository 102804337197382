.teamup-clipboard {
  padding-bottom: px2rem(20);

  .btn {
    border-width: 2px;

    --bs-btn-padding-y: 0.75rem;

    font-weight: normal;
    background-image: linear-gradient(#{var(--teamup-blue)}, #{var(--teamup-blue)}), linear-gradient(rgb(249 250 252 / 40%), rgb(249 250 252 / 40%));
    background-size: #{px2rem(50)} 100%, auto 100%;
    background-repeat: no-repeat;
    background-position: right, left;
    font-size: var(--font-size--instruction);
    box-shadow: 0 1px 10px #0000000F;

    [class^="icon-"] {
      color: var(--teamup-white);
      font-size: 1.3rem;
      transform: translateX(0.2rem);
    }

    & + * {
      margin-top: px2rem(20);
      padding-left: px2rem(20);
    }

    &--success {
      --bs-btn-color: var(--teamup-white);
      --bs-btn-border-color: var(--teamup-light-green-2);

      background-image: linear-gradient(transparent, transparent), linear-gradient(#{var(--teamup-light-green-2)}, #{var(--teamup-light-green-2)});
    }

    &--error {
      --bs-btn-color: var(--teamup-red);
      --bs-btn-border-color: var(--teamup-red);

      background-image: linear-gradient(transparent, transparent), linear-gradient(#{var(--teamup-red)}, #{var(--teamup-red)});
    }

    &-text {
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 60px);
      text-overflow: ellipsis;
    }
  }
}

* .teamup-clipboard {
  margin-top: px2rem(20);
}