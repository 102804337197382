.teamup-search {
  &__input {
    background-color: var(--teamup-white);
    border-radius: var(--teamup-border);
    box-shadow: var(--teamup-box-shadow);
    display: grid;
    grid-template-columns: px2rem(17) 1fr auto;
    grid-gap: px2rem(15);
    padding: 0.5rem 1rem;
    align-items: center;
    color: var(--teamup-grey-2);

    input {
      border: none;

      &[type="text"] {
        color: var(--teamup-dark-blue);
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    button[type="reset"] {
      border: none;
      background-color: var(--teamup-dark-blue);
      color: var(--teamup-white);
      border-radius: 100%;
      height: px2rem(20);
      width: px2rem(20);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size--h3);
    }
  }

  &__headline {
    color: var(--teamup-grey-2);
    font-size: var(--font-size--subtitle);
  }

  &__results {
    margin-top: px2rem(20);
    background-color: var(--teamup-white);
    box-shadow: var(--teamup-box-shadow);
    padding: px2rem(17) px2rem(20);
    border-radius: var(--teamup-border);

    &-headline {
      color: var(--teamup-blue-2);
      font-size: var(--font-size--subtitle);
      font-weight: bold;
      margin-bottom: px2rem(15);
      display: block;
    }

    &-entry {
      display: grid;
      grid-template-columns: px2rem(40) 1fr px2rem(20);
      grid-gap: px2rem(15);
      align-items: flex-end;
      width: 100%;

      img {
        width: px2rem(40);
        height: px2rem(40);
        object-fit: cover;
        border-radius: 100%;
      }

      &_name,
      &_email {
        font-size: var(--font-size--subtitle);
      }

      &_name {
        font-weight: bold;
        color: var(--teamup-blue-2);
      }

      &_email {
        color: var(--teamup-grey-2);
      }

      &_link {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size--h3);
      }

      & + & {
        margin-top: px2rem(17);
      }


    }

    &:empty {
      display: none;
    }

    .filter-wrapper-elements {
      & >.shuffle-item--hidden:first-child + .filter-element {
        margin-top: 0;
      }
    }
  }
}

* + .teamup-search {
  margin-top: px2rem(20);
}