.teamup-btn-filter-wrapper,
.teamup-form-check-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -0.35rem;

  & > * {
    margin: 0.35rem;
    width: auto;
    font-weight: normal;
    font-size: var(--font-size--labeling_1);
  }

  .form-checkbox + .form-checkbox,
  .form-radio + .form-radio,
  * + .form-checkbox,
  * + .form-radio {
    margin: 0.35rem;
  }
}