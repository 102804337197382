[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//.icon-bg {
//  background-color: var(--teamup-blue);
//  border-radius: 100%;
//  width: px2rem(20);
//  height: px2rem(20);
//}


.icon-calendar::before {
  content: "\e91b";
}

.icon-lamp::before {
  content: "\e912";
}

.icon-flag::before {
  content: "\e914";
}

.icon-lens::before {
  content: "\e915";
}

.icon-people::before {
  content: "\e916";
}

.icon-box::before {
  content: "\e917";
}

.icon-clock::before {
  content: "\e918";
}

.icon-copy::before {
  content: "\e919";
}

.icon-download::before {
  content: "\e91a";
}

.icon-drag::before {
  content: "\e913";
}

.icon-lock::before {
  content: "\e911";
}

.icon-close::before {
  content: "\e910";
}

.icon-eye-open::before {
  content: "\e90d";
}

.icon-correct::before {
  content: "\e90e";
}

.icon-trash::before {
  content: "\e90f";
}

.icon-play::before {
  content: "\e903";
}

.icon-eye::before {
  content: "\e909";
}

.icon-stats::before {
  content: "\e901";
}

.icon-filter::before {
  content: "\e90c";
}

.icon-graduation-cap::before {
  content: "\e90b";
}

.icon-course::before {
  content: "\e90a";
}

.icon-home::before {
  content: "\e908";
}

.icon-arrow-left::before {
  content: "\e900";
}

.icon-plus::before {
  content: "\e902";
}

.icon-edit::before {
  content: "\e904";
}

.icon-points::before {
  content: "\e905";
}

.icon-arrow-right::before {
  content: "\e906";
}

.icon-help::before {
  content: "\e907";
}



////

.icon-stats::before {
  color: var(--teamup-blue);
 opacity: 0.3;
}

.icon-lens {
  font-size: px2rem(20);
}

.icon-reload {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'%3e%3cg id='Gruppe_2456' data-name='Gruppe 2456' transform='translate(942 -11445)'%3e%3cg id='Gruppe_2113' data-name='Gruppe 2113' transform='translate(-942 11445)'%3e%3cg id='Gruppe_8' data-name='Gruppe 8'%3e%3cpath id='Pfad_11' data-name='Pfad 11' d='M17.5,0A17.5,17.5,0,1,0,35,17.5,17.519,17.519,0,0,0,17.5,0Z' fill='%23aeb7c6'/%3e%3c/g%3e%3c/g%3e%3cg id='Gruppe_1493' data-name='Gruppe 1493' transform='translate(-1256.001 10746.5)'%3e%3cg id='Gruppe_1093' data-name='Gruppe 1093' transform='translate(324.001 708.5)'%3e%3cpath id='Pfad_286' data-name='Pfad 286' d='M18.972,8.391A7.751,7.751,0,1,0,12.25,20,7.86,7.86,0,0,0,19.5,14.987' transform='translate(-4.5 -4.5)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3e%3cpath id='Pfad_287' data-name='Pfad 287' d='M209.149,30.822l4.131.368.2-4.3' transform='translate(-197.981 -26.265)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: px2rem(35);
  height: px2rem(35);
}