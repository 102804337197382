$btn-bg-color: #1eb4ff;
$font-family-base: 'Lato', sans-serif;
$font-size-base: #{px2rem(14)} ; //14.5px
$input-padding-y: 0.6rem;
$input-padding-x: #{px2rem(15)};
$input-font-size: var(--font-size--instruction);
$input-font-family: 'Roboto';
$input-color: var(--bs-body-color);
$form-range-track-bg: var(--teamup-light-grey);
$form-range-thumb-bg: var(--teamup-dark-blue);
$input-border-color: var(--teamup-white);
$input-color: var(--teamup-blue-2);
$input-focus-color: var(--teamup-blue-2);
$input-focus-border-color: var(--teamup-white);
$input-focus-box-shadow: 0 4px 10px -1px rgb(0 0 0 / 10%);

:root {
  --bs-body-color: var(--teamup-purple);
  --font-size--h1: #{px2rem(45)}; //60px;
  --font-size--h2: #{px2rem(35)}; //26.25px;
  --font-size--h3: #{px2rem(20)}; //15px;
  --font-size--title-screen_1: #{px2rem(16)}; //12px;
  --font-size--title-screen_2: #{px2rem(13)}; //19.75px;
  --font-size--button: #{px2rem(20)}; //15px;
  --font-size--instruction: #{px2rem(16)}; //12px;
  --font-size--subtitle: #{px2rem(14)}; //10.5px;
  --font-size--labeling_1: #{px2rem(13)}; //19.75px;
  --font-size--labeling_2: #{px2rem(12)}; //9px;
  --bertelsmann-blue: #004685;
  --teamup-blue: #1eb4ff;
  --teamup-blue-2: #0C55A6;
  --teamup-blue-3: #0086C7;
  --teamup-blue-4: #00417C;
  --teamup-blue-5: #0086BF;
  --teamup-blue-6: #009EE1;
  --teamup-dark-blue: #002B6F;
  --teamup-grey-transparent: #0009;
  --teamup-light-grey: #AEB7C6;
  --teamup-grey: #919CA7;
  --teamup-grey-2: #7F8CA5;
  --teamup-grey-3: #707070;
  --teamup-grey-4: #EFEFEF;
  --teamup-white: #fff;
  --teamup-white-2: #fdfdfd;
  --teamup-green: #38786D;
  --teamup-light-green: #50D0AF;
  --teamup-light-green-2: #2BB793;
  --teamup-turquoise: #59FFD4;
  --teamup-purple: #00194B;
  --teamup-dark-green: #1B6A56;
  --teamup-red: #B9073C;
  --teamup-border: #{px2rem(24)};
  --teamup-border-color: #EDF1F5;
  --teamup-box-shadow: 0 4px 10px -1px rgb(0 0 0 / 10%);
  --bs-link-color: var(--teamup-blue-2) !important;
}

$h5-font-size: var(--font-size--instruction);
$headings-color: var(--teamup-blue-2);
$headings-font-weight: 700;

//$input-border-radius: #{px2rem(15)};
$input-border-radius: 2rem;