.teamup-course-start {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: px2rem(40);

  --bs-gutter-x: 4.5rem;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: px2rem(80);
      height: px2rem(80);
      margin-bottom: px2rem(20);
    }
  }

  &__title {
    margin-bottom: 0.25rem;
  }

  &__subtitle {
    color: var(--teamup-blue);
  }

  &__content {
    margin-top: px2rem(50);
    font-size: px2rem(14.5);
    color: var(--teamup-purple);

    &-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-title {
      font-size: var(--font-size--subtitle);
      color: var(--teamup-blue);
      font-weight: normal;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      color: var(--teamup-grey);
      margin-right: px2rem(10);
    }

    &-icon {
      color: var(--teamup-blue-2);

      &:not(:last-child) {
        margin-right: px2rem(20);
      }
    }
  }
}