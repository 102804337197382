.teamup-value-conflicts-evaluation {
  .filter {
    &-wrapper-elements {
      & + * {
        margin-top: px2rem(20);
      }
    }

    &-element {
      width: 100%;
    }
  }


}