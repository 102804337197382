.teamup-info-card {
  display: grid;
  grid-gap: px2rem(15);
  grid-template-columns: px2rem(40) 1fr auto;
  align-items: flex-end;

  img {
    width: px2rem(40);
    height: px2rem(40);
    border-radius: px2rem(8);
    background-color: rgba(#AEB7C6,.25);
  }

  &__title,
  &__time {
    @extend .h5;

    margin-bottom: 0;
    font-size: var(--font-size--subtitle);
  }

  &__title {
    & + .teamup-info-card__subtitle {
      margin-top: 0;
    }
  }

  &__subtitle {
    display: block;
    font-size: var(--font-size--subtitle);
    color: var(--teamup-light-grey);
  }

  & + & {
    margin-top: px2rem(15);
  }
}