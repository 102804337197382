.form-control {
  box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.1);

  &::placeholder {
    font-style: italic;
  }

  &--light-grey,
  &--light-grey:focus {
    box-shadow: none;
    border: none;
    background-color: var(--teamup-border-color);
  }
}