.nav-tabs {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-link-active-bg: transparent;

  background-color: var(--teamup-white);
  box-shadow: inset 0 3px 6px rgb(0 0 0 / 16%);

  .nav-link {
    --bs-nav-link-padding-x: 0.5rem;
    --bs-nav-link-padding-y: 0.75rem;
    --bs-nav-tabs-link-hover-border-color: transparent;
    --bs-nav-link-color: var(--teamup-light-grey);
    --bs-nav-link-font-size: var(--font-size--labeling_2);

    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      color: var(--teamup-light-grey);
    }

    &.active {
      --bs-nav-tabs-link-active-color: var(--teamup-blue);
      --bs-nav-tabs-link-active-border-color: transparent;

      &:hover {
        color: var(--teamup-blue);
      }
    }

    [class^="icon-"], [class*=" icon-"] {
      margin-bottom: px2rem(20);
      font-size: 1.5rem;

      & + span {
        text-align: center;
      }
    }
  }
}

.teamup-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &--5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}