.modal {
  --bs-modal-border-radius: var(--teamup-border);
  --bs-modal-padding: #{px2rem(25)};
  --bs-modal-header-padding-x: var(--bs-modal-padding);
  --bs-modal-header-padding-y: var(--bs-modal-padding);
  --bs-modal-header-padding: var(--bs-modal-padding) var(--bs-modal-padding);
  --bs-modal-border-width: 0;
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
  --bs-modal-width: px2rem(314);

  &-title {
    margin-bottom: px2rem(15);
  }

  .btn-close {
    border-radius: 100%;
    border: none;
    width: px2rem(35);
    height: px2rem(35);
    padding: 0;
    z-index: 1;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: px2rem(35) px2rem(35);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'%3e%3cg transform='translate(-335 -43)'%3e%3cg transform='translate(370 43) rotate(90)' fill='%23fff' stroke='%231eb4ff' stroke-width='1.25'%3e%3ccircle cx='17.5' cy='17.5' r='17.5' stroke='none'/%3e%3ccircle cx='17.5' cy='17.5' r='16.875' fill='none'/%3e%3c/g%3e%3cg transform='translate(-5349.32 -7216.507) rotate(-45)'%3e%3cg transform='translate(-1114.825 9185.818) rotate(-90)' fill='%23fff' stroke='%231eb4ff' stroke-width='1.25'%3e%3crect width='15.5' height='2' rx='1' stroke='none'/%3e%3crect x='0.625' y='0.625' width='14.25' height='0.75' rx='0.375' fill='none'/%3e%3c/g%3e%3cg transform='translate(-1121.575 9177.068)' fill='%23fff' stroke='%231eb4ff' stroke-width='1.25'%3e%3crect width='15.5' height='2' rx='1' stroke='none'/%3e%3crect x='0.625' y='0.625' width='14.25' height='0.75' rx='0.375' fill='none'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
  }

  &--transparent {
    --bs-modal-bg: transparent;
    --bs-modal-border-width: 0;
    --bs-modal-header-border-width: 0;
  }

  &--small,
  &--textarea {
    --bs-modal-width: #{px2rem(315)};
    --bs-card-border-radius: #{px2rem(20)};

    .modal {
      &-header {
        padding-bottom: 0;
      }

      &-body {
        padding-top: 0;
      }
    }

    .card {
      &-header {
        padding-top: px2rem(25);
        padding-bottom: 0;
      }

      &-body {
        background: none;
      }
    }
  }
}