.teamup-value-profiles {
  background-image: url("images/hand-swipe.svg");
  background-size: px2rem(103) auto;
  background-repeat: no-repeat;
  background-position: bottom left 2.5rem;
  height: 100%;

  .card {
    &-header {
      padding-top: #{px2rem(25)}
    }

    &--radio {
      --bs-card-color: var(--teamup-turquoise);

      .card {
        &-header {
          div {
            width: 100%;
          }
        }

        &-body {
          padding-top: 0;
        }

        &-title {
          font-size: px2rem(18);
          text-align: center;
          color: var(--teamup-white);
        }
      }

      .form-check-wrapper {
        & + * {
          margin-top: px2rem(10);
        }
      }

      .form-check-input {
        & + label::before {
          border-color: var(--teamup-turquoise);
          opacity: 0.8;
          mix-blend-mode: normal;
          isolation: isolate;
        }

        &:checked {
          & + label::before {
            opacity: 1;
          }
        }
      }

      & + .card {
        .card-body {
          padding-top: 0.5rem;
        }
      }
    }

    &-title {
      + span {
        margin-top: px2rem(15);
      }
    }

    &-body {
      background-image: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: #{px2rem(25)};
    }

    &:not(.card--radio) {
      .card-header {
        padding-bottom: 0;

        span {
          color: var(--teamup-blue-6);
          font-size: var(--font-size--subtitle);
          display: block;
        }
      }
    }
  }
}

.teamup__evaluation--swiper{
  .swiper-slide{
    width: 320px;
  }

  .swiper-pagination {
    text-align: center;
    margin-top: 5px;
    transition: .3s opacity;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-block;
    margin: 5px;
    border-radius: var(--swiper-pagination-bullet-border-radius,50%);
    background: var(--teamup-light-grey);
  }

  .swiper-pagination-bullet-active {
    background: var(--teamup-blue-6);
  }

  .card-body__title--color{
    margin-bottom: px2rem(15);

    &-dark-blue{
      color: var(--teamup-blue-4);
    }

    &-dark-green{
      color: var(--teamup-dark-green);
    }

    &-light-blue{
      color: var(--teamup-blue-3);
    }

    &-light-green{
      color: var(--teamup-light-green-2);
    }
  }

  .value-with-rating {
    color: var(--teamup-grey-2);
    margin-bottom: 10px;
  }

  .teamup--rating-bar{
    min-width: 1%;
    height: 4px;
    display: block;

    &-color-dark-blue{
      background-color: var(--teamup-blue-4);
    }

    &-color-dark-green{
      background-color: var(--teamup-dark-green);
    }

    &-color-light-blue{
      background-color: var(--teamup-blue-3);
    }

    &-color-light-green{
      background-color: var(--teamup-light-green-2);
    }
  }
}