.swiper {
  &-button {
    &-prev,
    &-next {
      --swiper-navigation-size: 1rem;
      --swiper-theme-color: var(--teamup-white);

      border-radius: 100%;
      background-color: var(--teamup-light-grey);
      width: px2rem(35);
      height: px2rem(35);
      top: calc(50% - #{px2rem(10)});
    }

    &-prev {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &-navigation {
    position: relative;
    display: flex;
    width: px2rem(110);
    height: px2rem(35);
  }
}

.teamup--swiper {
  .teamup__footer {
    background-color: var(--teamup-white);
    padding-top: px2rem(10);
    height: 100px;

    .container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-areas: '. swiper-navigation step-wrapper';
      align-items: center;
    }

    .step-wrapper {
      position: absolute;
      color: var(--teamup-blue);
      right: 10%;
    }
  }

  .swiper {
    &-slide {
      @extend .container;

      opacity: 0;
      transition: opacity .5s;

      &-active {
        opacity: 1;
      }
    }

    &-navigation {
      grid-area: swiper-navigation;
      margin: 0 auto;
    }
  }
}