.teamup-chat {
  --titleColor: #{var(--teamup-dark-green)};
  --chatBG: #{var(--teamup-dark-green)};

  &__title {
    color: var(--titleColor);
    font-size: var(--font-size--button);
    text-align: center;
    margin-bottom: px2rem(20);
    display: block;
  }

  form {
    margin-top: px2rem(20);
  }

  .form-control {
    box-shadow: none;
    border: none;
    background-color: var(--teamup-border-color);
  }

  &__content {
    min-height: 250px;

    &-chat {
      background-color: var(--chatBG);
      color: var(--teamup-white);
      border-radius: px2rem(16);
      border-bottom-left-radius: 0;
      padding: px2rem(15);
      position: relative;
      width: 100%;
      max-width: calc(100% - #{px2rem(80)});
      margin-left: px2rem(18);

      &::before {
        content: '';
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M0,0V18H18A40.914,40.914,0,0,1,0,0Z' fill='%231B6A56'/%3e%3c/svg%3e");
        background-size: px2rem(18) px2rem(18);
        background-repeat: no-repeat;
        left: calc(#{px2rem(18)} * -1);
        bottom: 0;
        position: absolute;
        width: px2rem(18);
        height: px2rem(18);
        transform: scaleX(-1);
      }

      & + * {
        margin-top: px2rem(15);
      }

      &--user {
        background-color: var(--teamup-blue);
        background-position: right bottom;
        border-bottom-left-radius: px2rem(16);
        border-bottom-right-radius: 0;
        margin-left: auto;
        margin-right: px2rem(18);

        &::before {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M0,0V18H18A40.914,40.914,0,0,1,0,0Z' fill='%231eb4ff'/%3e%3c/svg%3e");
          left: inherit;
          right: calc(#{px2rem(18)} * -1);
          transform: scaleX(1);
        }
      }
    }
  }

  &--color-dark-blue {
    --titleColor: #{var(--teamup-dark-blue)};
    --chatBG: #{var(--teamup-dark-blue)};

    .teamup-chat__content-chat:not(.teamup-chat__content-chat--user) {
      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M0,0V18H18A40.914,40.914,0,0,1,0,0Z' fill='%23002B6F'/%3e%3c/svg%3e");
      }
    }
  }

  &--color-light-green-2 {
    --titleColor: #{var(--teamup-light-green-2)};
    --chatBG: #{var(--teamup-light-green-2)};

    .teamup-chat__content-chat:not(.teamup-chat__content-chat--user) {
      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3e%3cpath d='M0,0V18H18A40.914,40.914,0,0,1,0,0Z' fill='%232BB793'/%3e%3c/svg%3e");
      }
    }
  }
}