.teamup {
  &-color-blue {
    color: var(--teamup-blue) !important;
  }

  &-color-blue-2 {
    color: var(--teamup-blue-2) !important;
  }

  &-color-blue-3 {
    color: var(--teamup-blue-3) !important;
  }

  &-color-blue-4 {
    color: var(--teamup-blue-4) !important;
  }

  &-color-blue-5 {
    color: var(--teamup-blue-5) !important;
  }

  &-color-blue-6 {
    color: var(--teamup-blue-6) !important;
  }

  &-color-blue-dark {
    color: var(--teamup-dark-blue) !important;
  }

  &-color-grey-light {
    color: var(--teamup-light-grey) !important;
  }

  &-color-grey {
    color: var(--teamup-grey) !important;
  }

  &-color-grey-2 {
    color: var(--teamup-grey-2) !important;
  }

  &-color-grey-3 {
    color: var(--teamup-grey-3) !important;
  }

  &-color-white {
    color: var(--teamup-white) !important;
  }

  &-color-white-2 {
    color: var(--teamup-white-2) !important;
  }

  &-color-green {
    color: var(--teamup-green) !important;
  }

  &-color-green-light {
    color: var(--teamup-light-green) !important;
  }

  &-color-green-light-2 {
    color: var(--teamup-light-green-2) !important;
  }

  &-color-turquoise {
    color: var(--teamup-turquoise) !important;
  }

  &-color--purple {
    color: var(--teamup-purple) !important;
  }

  &-color--green-dark {
    color: var(--teamup-dark-green) !important;
  }

  &-color-red {
    color: var(--teamup-red) !important;
  }
}