.teamup-login {
  min-height: 100vh;
  width: 100%;
  display: grid;
  background-image: url("images/hintergrund-satt.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;

  &::before {
    content: '';
    background-image: url("images/hintergrund-soft-pop-up.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity .5s;
    animation-name: bgFadeIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  & > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  &__welcome {
    display: flex;
    align-items: center;
    justify-content: center;

    &-footer {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: var(--bertelsmann-blue);
      font-size: px2rem(14.5);
      letter-spacing: 0.02em;
      margin-top: px2rem(13.5);
      margin-bottom: px2rem(56.75);
      animation-name: fadeOut;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }

  &__form {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;

    .forgot-password {
      text-align: center;
      margin-top: px2rem(20);
      display: block;
      width: 100%;
    }

    h1,
    .new-user {
      font-size: var(--font-size--instruction);
      font-weight: 700;
      letter-spacing: px2rem(0.24);
      text-align: center;
      color: var(--teamup-blue-2);
    }

    h1 {
      margin-top: px2rem(37.5);
      margin-bottom: px2rem(18);
      animation-name: loginHeadline;
      animation-duration: 3s;
    }

    a:not(.btn) {
      color: var(--teamup-grey-2);
    }

    &-logo {
      display: block;
      margin: 0 auto;
      height: px2rem(40);
      animation-name: loginLogo;
      animation-duration: 3s;
    }

    footer {
      display: flex;
      justify-content: center;
      animation-name: fadeIn;
      animation-duration: 3s;
    }

    nav {
      display: flex;
      flex-wrap: wrap;
      margin: -0.5rem;

      & > * {
        margin: 0.5rem;
        position: relative;

        &:not(:first-child) {
          margin-left: 0;
          padding-left: 1rem;

          &::before {
            content: '•';
            position: absolute;
            left: 0;
          }
        }
      }
    }

    .form-control {
      color: var(--teamup-grey);
    }
  }
}

.teamup-password-viewer {
  position: relative;
  display: flex;
  align-items: center;

  &__button {
    position: absolute;
    right: $input-padding-x;
    background-color: transparent;
    border: none;
    color: var(--teamup-light-grey);
  }
}

.login-form {
  animation-name: loginForm;
  animation-duration: 3s;
}

@keyframes loginLogo {
  0% {
    height: px2rem(55);
  }

  45% {
    height: px2rem(55);

  }

  100% {
    height: px2rem(40);

  }
}


@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  90% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bgFadeIn {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loginForm {
  0% {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
  }

  45% {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
  }

  90% {
    max-height: 999px;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loginHeadline {
  0% {
    max-height: 0;
    visibility: hidden;
    margin: 0;
    opacity: 0;
  }

  45% {
    max-height: 0;
    visibility: hidden;
    margin: 0;
    opacity: 0;
  }

  90% {
    margin-top: 2.34375rem;
    margin-bottom: 1.125rem;
    max-height: 999px;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}