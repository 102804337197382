.form-radio--img {
  background-image: var(--img-path);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 0;
  border-radius: px2rem(20);
  border: 2px solid transparent;

  &::before{
    content: ' ';
    display: block;
    width: 100%;
    padding-top: 100%
  }

  .form-check-input {
    border-color: var(--teamup-turquoise);
    position: absolute;
    top: px2rem(15);
    right: px2rem(15);

    &:checked {
      border-color: var(--teamup-turquoise);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2359FFD4'/%3e%3c/svg%3e");
      background-size: 130%;
    }

    & + label {
      &::before {
        border: none;
        background-image: none;
      }

      span {
        @include visually-hidden;
      }


      &::after {
        content: none;
      }
    }
  }

  & + .form-radio--img {
    margin-top: 0;
  }


  &-blue {
    .form-check-input {
      border-color: var(--teamup-blue);

      &:checked {
        border-color: var(--teamup-blue);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231eb4ff'/%3e%3c/svg%3e");
      }
    }
  }
}

.form-radio-img-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;

  &--is-checked {
    & > * {
      opacity: 0.5;
      border-color: var(--teamup-turquoise);
    }

    .is-checked {
      opacity: 1;
    }
  }

  &--3-columns {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: px2rem(15);

    .form-check-input {
      top: px2rem(8);
      right: px2rem(8);
    }
  }

  &--blue {
    &.form-radio-img-wrapper--is-checked {
      & > * {
        border-color: var(--teamup-blue);
      }
    }
  }
}