.teamup-alias {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .teamup-logo {
    display: block;
    margin: 0 auto;
    height: px2rem(55);
    margin-bottom: px2rem(20);
  }

  label {
    color: var(--teamup-blue);
  }
}