.teamup-statistic {
  position: relative;
  padding-bottom: px2rem(120);

  &__graph {
    position: relative;
    border-radius: var(--teamup-border);
    background-image: linear-gradient(to right, var(--teamup-light-green), var(--teamup-blue));
    padding: 2px;

    & > * {
      width: 100%;
      height: 100%;
      border-radius: var(--teamup-border);
      padding-top: 100%;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }

    & + * {
      margin-top: 1rem;
    }
  }

  .swiper-slide {
    --colorTheme: var(--teamup-dark-green);

    width: 80%;
    padding: px2rem(20);
    background-color: var(--teamup-white);
    border-radius: var(--teamup-border);
    margin-right: 1rem;
    opacity: 1;

    h3 {
      color: var(--colorTheme);
      font-size: var(--font-size--title-screen_1);
      margin-bottom: 1rem;
    }

    div {
      color: var(--teamup-grey-2);
      font-size: var(--font-size--labeling_1);
    }

    div + div {
      margin-top: 1rem;
    }

    &--light-blue {
      --colorTheme: var(--teamup-blue-3);
    }

    &--light-green {
      --colorTheme: var(--teamup-light-green-2);
    }

    &--blue {
      --colorTheme: var(--teamup-blue-4);
    }

  }

  .line {
    width: var(--line-width);
    height: px2rem(3.5);
    background-color: var(--colorTheme);
    display: block;
    margin-top: 0.25rem;
  }

  &__help {
    margin-left: auto;
    z-index: 1;
    position: relative;

    & + * {
      transform: translateY(-2.5rem);
      display: block;
    }
  }

  &__save {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: var(--teamup-white);
    width: 100%;
    padding: px2rem(20);
  }
}