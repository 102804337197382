.teamup--course-config-admin {
  .card {
    &--description {
      overflow: hidden;

      .accordion {
        --bs-accordion-border-radius: 0;
        --bs-accordion-border-width: 0;
        --bs-accordion-color: var(--teamup-light-grey);

        margin-top: 1.25rem;
        margin-left: -1.25rem;
        width: calc(100% + (2* 1.25rem));

        &-body {
          padding-top: 0;
        }

        .card {
          &-header {
            align-items: flex-end;
          }

          & + .card {
            margin-top: 0;
          }
        }

        &-button {
          color: var(--teamup-grey-2);

          &::after {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='100%25' height='100%25' viewBox='0 0 15 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3e%3cg transform='matrix(-3.82857e-16,-1,-1,3.82857e-16,14.682,8.66609)'%3e%3cg%3e%3cpath d='M8.293,0.391C8.052,0.141 7.719,-0 7.371,-0C7.024,-0 6.691,0.141 6.45,0.391L0.382,6.424C0.137,6.668 -0.001,7 -0.001,7.345C-0.001,7.691 0.137,8.023 0.382,8.267L6.45,14.3C6.684,14.547 7.01,14.685 7.35,14.682C7.353,14.682 7.355,14.682 7.358,14.682C8.071,14.682 8.658,14.095 8.658,13.382C8.658,13.035 8.518,12.701 8.271,12.457L3.147,7.345L8.275,2.234C8.526,1.989 8.667,1.654 8.667,1.304C8.667,0.962 8.533,0.634 8.293,0.391Z' style='fill:%237F8CA5;fill-rule:nonzero;'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
            background-position: center;
            width: px2rem(14);
            height: px2rem(9);
            background-size: contain;
          }

          &:not(.collapsed) {
            padding-bottom: 0;
            background-color: var(--teamup-white);
          }
        }
      }

      &-info {
        .teamup-icon-with-label {
          color: var(--teamup-white);
        }

        .d-flex {
          margin-top: px2rem(10);

          div + div {
            margin-top: 0;
            margin-left: px2rem(20);
          }
        }
      }
    }

    &-body {
      padding-bottom: 0;
    }
  }

  .teamup-timeline {
    display: block;
    background-color: var(--teamup-white);

    .card {
      --bs-card-border-radius: 0;
      --bs-card-spacer-y: 0;
      --bs-card-spacer-x: 0;

      box-shadow: none;

      &-title {
        color: var(--teamup-blue-2);
      }

      &::before,
      &::after {
        content: none;
      }
    }
  }
}