.teamup-options {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    padding-top: px2rem(12);
    padding-bottom: px2rem(12);
    border-top: 1px solid var(--teamup-white);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .teamup-options__title {
      color: var(--teamup-blue);
      font-size: var(--font-size--title-screen_1);
    }

    &.disabled {
      pointer-events: none;

      & > * {
        opacity: .5;
      }
    }

    .form-check-label {
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }

  &__text-button {
    display: flex;
    align-items: center;
    color: var(--teamup-grey-2);

    span + * {
      margin-left: 1rem;
    }
  }
}

* + .teamup-options,
.filter-element .teamup-options {
  margin-top: px2rem(20);
}