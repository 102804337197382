.teamup-course-info {
  display: flex;

  img {
    width: px2rem(52);
    height: px2rem(52);

    & + * {
      margin-left: px2rem(15);
    }
  }

  .teamup-icon-with-label {
    margin-bottom: 0;

    & + .teamup-icon-with-label {
      margin-top: 0;
      margin-left: 1.25rem;
    }
  }
}

* + .teamup-course-info {
  margin-top: px2rem(20);
}