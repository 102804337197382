@mixin bg-gradient-green-to-blue() {
  background-image: linear-gradient(#{var(--teamup-light-green)}, #{var(--teamup-blue)});
}

@mixin bg-gradient-blue-to-blue() {
  background-image: linear-gradient(#{var(--teamup-dark-blue)}, #{var(--teamup-dark-blue)})
}

@mixin btn-instruction() {
  width: px2rem(35);
  height: px2rem(35);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: var(--font-size--instruction);
  color: var(--teamup-white);
  background-color: var(--teamup-blue);
  border-radius: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--teamup-white);
  }
}