.btn {
  --bs-btn-bg: #{$btn-bg-color};
  --bs-btn-border-color: #{$btn-bg-color};
  --bs-btn-border-radius: 2rem;
  --bs-btn-font-family: var(--bs-body-font-family);
  --bs-btn-font-size: var(--font-size--button);
  --bs-btn-font-weight: 700;
  --bs-btn-color: var(--teamup-white);
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{darken($btn-bg-color, 15%)};
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-disabled-color: rgb(255 255 255 / 50%);
  --bs-btn-disabled-bg: rgb(30 180 255 / 50%);
  --bs-btn-disabled-border-color: transparent;

  width: 100%;

  &--text-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--with-counter,
  &--with-counter-blue {
    margin-left: px2rem(10);
    display: flex;
    align-items: center;
    width: calc(100% - #{px2rem(10)});

    --bs-btn-font-weight: normal;
    --bs-btn-padding-x: 1.25rem;

    position: relative;

    .counter {
      z-index: 1;
      width: px2rem(20);
      height: px2rem(20);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--teamup-turquoise);
      border-radius: 100%;
      border: 2px solid var(--teamup-blue);
      background-color: var(--teamup-dark-blue);
      font-family: Roboto;
      font-weight: 500;
      font-size: 10px;
      position: absolute;
      left: calc(#{px2rem(10)} * -1);
    }

    [class^="icon-"], [class*=" icon-"] {
      margin-left: auto;
    }

    & + * {
      margin-top: px2rem(20);
    }
  }

  &--with-counter-blue {
    --bs-btn-bg: var(--teamup-blue-2);
    --bs-btn-border-color: var(--teamup-blue-2);
  }

  &--circle {
    //--bs-btn-color: #fff;
    --bs-btn-border-radius: 100%;

    width: px2rem(35); //26.25
    height: px2rem(35); //26.25
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;

    .icon-download {
      font-size: 1.25rem;
    }

    .icon-close {
      font-size: 2rem;
    }

    .icon-points,
    .icon-edit,
    .icon-lock,
    .icon-arrow-left,
    .icon-box,
    .icon-copy,
    .icon-eye {
      font-size: 1rem;
    }

    .icon-arrow-left {
      transform: translateX(-0.1rem);
    }
  }

  &--close {
    --bs-btn-border-color: var(--teamup-white);
    --bs-btn-bg: transparent;

    font-size: 2rem; // because the clsoe icon is small
  }

  &:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
  }

  &-secondary {
    --bs-btn-bg: transparent;
    --bs-btn-color: #{$btn-bg-color};
    --bs-btn-disabled-color: rgb(30 180 255 / 50%);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: rgb(30 180 255 / 50%);

    box-shadow: 0 1px 10px #0000000F;
    border-width: 2px;

    &:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
    }
  }

  &--grey {
    --bs-btn-bg: var(--teamup-light-grey);
    --bs-btn-border-color: var(--bs-btn-bg);

    &.btn-secondary {
      --bs-btn-bg: transparent;
      --bs-btn-color: var(--teamup-light-grey);
      --bs-btn-border-color: var(--bs-btn-color);

      &:hover {
        color: var(--bs-btn-color);
        background-color: var(--bs-btn-bg);
      }
    }

    &:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: var(--bs-btn-border-color)
    }
  }

  &--blue {
    --bs-btn-bg: var(--teamup-blue-3);
    --bs-btn-border-color: var(--bs-btn-bg);

    &.btn-secondary {
      --bs-btn-bg: transparent;
      --bs-btn-color: var(--teamup-blue-3);
      --bs-btn-border-color: var(--bs-btn-color);

      &:hover {
        color: var(--bs-btn-color);
        background-color: var(--bs-btn-bg);
      }
    }

    &:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: var(--bs-btn-border-color)
    }
  }

  &--green {
    --bs-btn-bg: var(--teamup-dark-green);
    --bs-btn-border-color: var(--bs-btn-bg);

    &.btn-secondary {
      --bs-btn-bg: transparent;
      --bs-btn-color: var(--teamup-dark-green);
      --bs-btn-border-color: var(--bs-btn-color);

      &:hover {
        color: var(--bs-btn-color);
        background-color: var(--bs-btn-bg);
      }
    }

    &:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: var(--bs-btn-border-color)
    }
  }

  &--light-green {
    --bs-btn-bg: var(--teamup-light-green-2);
    --bs-btn-border-color: var(--bs-btn-bg);

    &.btn-secondary {
      --bs-btn-bg: transparent;
      --bs-btn-color: var(--teamup-light-green-2);
      --bs-btn-border-color: var(--bs-btn-color);

      &:hover {
        color: var(--bs-btn-color);
        background-color: var(--bs-btn-bg);
      }
    }

    &:hover {
      color: var(--bs-btn-color);
      background-color: var(--bs-btn-bg);
      border-color: var(--bs-btn-border-color)
    }
  }

  &--active {
    --bs-btn-bg: var(--teamup-light-grey);
    --bs-btn-color: var(--teamup-white);
    --bs-btn-border-color: var(--bs-btn-bg);
  }
}