.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 2.25rem;
}

.section-separator {
  border-bottom: 1px solid var(--teamup-white);
  width: 100%;
  display: block;
  margin: px2rem(20) 0;

  &--small {
    margin: 0.9375rem 0;
  }

  &--grey {
    border-color: var(--teamup-border-color);
  }
}

.teamup {
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-rows: auto 1fr auto;
  grid-template-rows: px2rem(94) 1fr px2rem(94);
  grid-template-areas: 'teamupHeader' 'teamupContent' 'teamupFooter';
  min-height: 100vh;
  background-image: url("images/hintergrund-satt.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  transform: unset;

  &--bg_2 {
    background-image: url("images/hintergrund-soft-pop-up.svg");
  }

  &--bg_3,
  &--bg_4 {
    background-image: url("images/hintergrund-wellen.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;

    @media (min-height: 850px) {
      background-size: contain;
    }
  }

  &--bg_4 {
    background-image: url("images/zwischenscreen.svg");
  }

  &__header {
    grid-area: teamupHeader;
    display: flex;
    align-items: center;
    padding-top: px2rem(30);
    padding-bottom: px2rem(30);
    color: var(--teamup-white);

    .btn {
      width: px2rem(35);
      height: px2rem(35);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      // --bs-btn-border-color: var(--teamup-light-grey);
      // --bs-btn-hover-border-color: var(--teamup-light-grey);
      // --bs-btn-bg: var(--teamup-light-grey);
    }

    &-buttons {
      display: flex;
      flex-wrap: wrap;
      margin: -0.75rem;

      & > * {
        width: px2rem(35);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        margin: 0.75rem;
      }
    }

    .btn-primary {
      width: max-content;
      max-width: max-content;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: normal;
      font-size: var(--font-size--title-screen_1);
    }

    &-headline {
      color: var(--teamup-blue);
      width: calc(100% - 12rem);
      text-align: center;
      font-size: var(--font-size--title-screen_2);
      font-weight: 700;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &-profile {
      margin-left: auto;

      img {
        width: px2rem(45);
        height: px2rem(45);
        object-fit: cover;
      }
    }

    &-logo {
      height: px2rem(35);
    }

    &--with-counter {
      background-color: var(--teamup-light-grey);
      border-radius: px2rem(24);
      display: flex;
      align-items: center;
      color: var(--teamup-white);
      padding: px2rem(7) px2rem(10);
      font-size: var(--font-size--subtitle);
      font-family: Roboto;

      img {
        width: px2rem(20);
        height: px2rem(20);
        margin-right: px2rem(10);
      }
    }

    &--between {
      justify-content: space-between;
    }

    &--evaluation {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: auto;
    }
  }

  &--show-header {
    .teamup__header {
      z-index: 1025;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      padding-top: px2rem(15);
      padding-bottom: px2rem(15);
      top: -65px;
      transition: top .25s;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: var(--teamup-white);
        box-shadow: 0 4px 2px -2px rgba(0,0,0,25%);
        left: -50%;
        width: 9999px;
      }
    }

    &-down {
      .teamup__header {
        top: 0;
      }
    }
  }

  &__content {
    grid-area: teamupContent;
    overflow-y: auto;
    margin-top: px2rem(35);
    margin-bottom: px2rem(20);
    padding-bottom: px2rem(10);

    &--evaluation {
      margin-top: px2rem(20);
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    z-index: 1025;
    width: 100%;
    min-height: px2rem(94);
    grid-area: teamupFooter;

    & >:only-child {
      bottom: 1.5rem;
    }

    @media (min-width: 600px) {
      position: sticky;
    }
  }

  &-title {
    font-size: var(--font-size--instruction);
    text-align: center;
    margin-bottom: px2rem(20);

    & + .teamup-repsonsive-img {
      margin-bottom: px2rem(40);
    }

    &--left {
      text-align: left;
    }
  }

  &-pre-title {
    color: var(--teamup-dark-blue);
    font-size: var(--font-size--instruction);
    text-align: center;
    margin-bottom: px2rem(20);
  }

  &__background-graphic {
      position: absolute;
      bottom: px2rem(94);
      left: calc(50% - 140px);
  }
}

p:last-child {
  margin-bottom: 0;
}

a:not([href]) {
  color: rgba(255,255,255, 50%);
  background-color: rgba(30,180,255, 50%);
  border-color: transparent;
}

.teamup-repsonsive-img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  display: block;
}

.teamup-color-red {
  color: var(--teamup-red);
}

.img-fluid {
  border-radius: 1rem;

  &--is-icon {
    width: px2rem(78);
    height: px2rem(78);
    object-fit: cover;
  }
}