.form-checkbox {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
    width: px2rem(20);
    height: px2rem(20);
    border-radius: 100%;
    border-width: 2px;
    background-color: transparent;
    border-color: var(--teamup-turquoise);
    z-index: 2;
    flex-shrink: 0;

    & + label {
      color: var(--teamup-white);
      overflow: hidden;
      z-index: 1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: background-image .5s, border-color .5s, opacity .5s;
        border-radius: px2rem(20);
        width: 100%;
        height: 100%;
        z-index: -1;

        //border: 2px solid var(--teamup-blue);
        border-color: var(--teamup-turquoise);
      }

      &::before {
        background-image: linear-gradient(var(--teamup-dark-blue), var(--teamup-dark-blue));
      }

      &::after {
        cursor: pointer;
        border-color: var(--teamup-turquoise);

        //background-image: linear-gradient(to right, var(--teamup-light-green), var(--teamup-blue));
        background-image: linear-gradient(to right, var(--teamup-light-green), var(--teamup-blue-5));
        opacity: 0;
      }
    }

    &:checked {
      border-color: var(--teamup-white);

      & + label {
        color: var(--teamup-white);

        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .form-check-label {
    margin-left: 0.75rem;
    font-weight: bold;
  }

  &--hide-input {
    .form-check-input {
      @include visually-hidden;
    }
  }

  + .form-checkbox {
    margin-top: px2rem(20);
  }
}

* + .form-checkbox{
  margin-top: px2rem(20);
}

