.form-switch {
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding-left: 0;
  width: max-content;
  text-align: center;

  .form-check-input[type="checkbox"] {
    margin-left: 0;
    border: none;
    width: px2rem(40); // 40px
    height: px2rem(20); //20px
    background-color: var(--teamup-light-grey);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");

    &:checked {
      background-color: var(--teamup-blue);
    }

    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
    }
  }

  .form-check-label {
    margin-top: 0.5rem;
    font-size: var(--font-size--subtitle);
    color: var(--teamup-grey-transparent);
  }
}