.accordion {
  &-button {
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }

    &.teamup-color-grey-2 {
      &::after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='100%25' height='100%25' viewBox='0 0 15 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3e%3cg transform='matrix(-3.82857e-16,-1,-1,3.82857e-16,14.682,8.66609)'%3e%3cg%3e%3cpath d='M8.293,0.391C8.052,0.141 7.719,-0 7.371,-0C7.024,-0 6.691,0.141 6.45,0.391L0.382,6.424C0.137,6.668 -0.001,7 -0.001,7.345C-0.001,7.691 0.137,8.023 0.382,8.267L6.45,14.3C6.684,14.547 7.01,14.685 7.35,14.682C7.353,14.682 7.355,14.682 7.358,14.682C8.071,14.682 8.658,14.095 8.658,13.382C8.658,13.035 8.518,12.701 8.271,12.457L3.147,7.345L8.275,2.234C8.526,1.989 8.667,1.654 8.667,1.304C8.667,0.962 8.533,0.634 8.293,0.391Z' style='fill:%237F8CA5;fill-rule:nonzero;'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
      }
    }

  }

  &--checkbox {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 0;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2359ffd4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-padding-y: 0.5rem;
    --bs-accordion-color: var(--teamup-white);

    &.is-checked {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2359FFD4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion {
      &-item {
        position: relative;

        & + .accordion-item {
          margin-top: px2rem(15);
        }
      }

      &-header {
        display: grid;
        grid-template-columns: 1fr auto;

        & > :first-child {
          grid-area: 1 / 1 / 2 / 3;
        }

        & > :nth-child(2) {
          grid-area: 1 / 2 / 2 / 3;
          z-index: 1;
        }
      }

      &-body__title {
        color: var(--teamup-turquoise);
        text-transform: uppercase;
        font-family: Roboto;
        font-weight: bold;
        font-size: px2rem(10);
      }

      &-button {
        --bs-accordion-btn-bg: transparent;
        --bs-accordion-active-bg: transaprent;
      }

      &-collapse {
        position: relative;
        z-index: 1;
      }
    }

    .form-checkbox {
      position: static;
    }

    &.accordion {
      &--checked {
        --bs-accordion-color: var(--teamup-white);

        .accordion-body__title {
          color: var(--teamup-white);
        }
      }
    }
  }

  &--card {
    --bs-accordion-bg: var(--teamup-white);
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: var(--teamup-border);
    --bs-accordion-inner-border-radius: var(--bs-accordion-border-radius);
    --bs-accordion-btn-icon: url("data:image/svg+xml;charset=UTF-8,%3csvg width='100%25' height='100%25' viewBox='0 0 15 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3e%3cg transform='matrix(-3.82857e-16,-1,-1,3.82857e-16,14.682,8.66609)'%3e%3cg%3e%3cpath d='M8.293,0.391C8.052,0.141 7.719,-0 7.371,-0C7.024,-0 6.691,0.141 6.45,0.391L0.382,6.424C0.137,6.668 -0.001,7 -0.001,7.345C-0.001,7.691 0.137,8.023 0.382,8.267L6.45,14.3C6.684,14.547 7.01,14.685 7.35,14.682C7.353,14.682 7.355,14.682 7.358,14.682C8.071,14.682 8.658,14.095 8.658,13.382C8.658,13.035 8.518,12.701 8.271,12.457L3.147,7.345L8.275,2.234C8.526,1.989 8.667,1.654 8.667,1.304C8.667,0.962 8.533,0.634 8.293,0.391Z' style='fill:rgb(12,85,166);fill-rule:nonzero;'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);
    --bs-accordion-btn-icon-width: #{px2rem(14)};
    --bs-accordion-btn-padding-y: 0.95rem;
    --bs-accordion-active-color: var(--teamup-blue-2);

    //--bs-accordion-color: var(--teamup-white);

    .accordion {
      &-item {
        position: relative;
        border-radius: var(--bs-accordion-inner-border-radius);

        & + .accordion-item {
          margin-top: px2rem(15);
        }
      }

      &-header {
        display: grid;
        grid-template-columns: 1fr auto;

        & > :first-child {
          grid-area: 1 / 1 / 2 / 3;
        }

        & > :nth-child(2) {
          grid-area: 1 / 2 / 2 / 3;
          z-index: 1;
        }
      }

      &-button {
        --bs-accordion-btn-color: var(--teamup-blue-2);
        --bs-accordion-btn-bg: transparent;
        --bs-accordion-active-bg: transaprent;

        @extend .h5;

        font-size: var(--font-size--subtitle);
        margin-bottom: 0;

        &::after {
          background-position: center;
        }
      }

      &-body {
        padding-top: 0;
        padding-bottom: px2rem(20);
      }

      &-collapse {
        position: relative;
        z-index: 1;
      }
    }

    //&.accordion {
    //  &--checked {
    //    --bs-accordion-color: var(--teamup-white);
    //
    //    .accordion-body__title {
    //      color: var(--teamup-white);
    //    }
    //  }
    //}

    .teamup-info-card {
      margin-top: px2rem(15);
    }
  }

  & + .accordion {
    margin-top: px2rem(15);
  }
}