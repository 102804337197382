.form-radio {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
    width: px2rem(20);
    height: px2rem(20);
    border-width: 2px;
    background-color: transparent;

    //border-color: var(--teamup-blue);
    border-color: var(--teamup-turquoise);
    z-index: 2;
    flex-shrink: 0;

    & + label {
      //color: var(--teamup-light-green);
      color: var(--teamup-white);
      overflow: hidden;
      z-index: 1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: background-image .5s, border-color .5s, opacity .5s;
        border-radius: px2rem(20);
        width: 100%;
        height: 100%;
        z-index: -1;

        //border: 2px solid var(--teamup-blue);
        border: 2px solid var(--teamup-turquoise);
      }

      &::before {
        background-image: linear-gradient(var(--teamup-dark-blue), var(--teamup-dark-blue));
      }

      &::after {
        cursor: pointer;
        border-color: var(--teamup-turquoise);
        background-image: linear-gradient(to right, var(--teamup-light-green), var(--teamup-blue-5));
        opacity: 0;
      }
    }

    &:checked {
      border-color: var(--teamup-white);

      & + label {
        color: var(--teamup-white);

        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .form-check-label {
    margin-left: 0.75rem;
  }

  &--hide-input {
    .form-check-input {
      @include visually-hidden;
    }
  }

  &--small-rounded {
    width: px2rem(40);
    height: px2rem(40);
    justify-content: center;

    .form-check-label {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  + .form-radio {
    margin-top: px2rem(20);
  }

  &--label-white {
    .form-check-input + label {
      color: var(--teamup-white);
    }
  }

  &--card {
    position: static;
    padding: 0;

    .form-check-input {
      border-color: var(--teamup-blue);
      cursor: pointer;

      & + label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 2px solid;
        border-radius: var(--teamup-border);
        margin: 0;
        pointer-events: none;

        &::before,
        &::after {
          content: none;
        }
      }

      &:checked {
        border-color: var(--teamup-blue);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231eb4ff'/%3e%3c/svg%3e");

        & + label {
          border-color: var(--teamup-blue);
        }
      }
    }

  }
}

* + .form-radio {
  margin-top: px2rem(20);
}

