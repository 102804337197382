.teamup-multi-button-select {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem;
  background-image: linear-gradient(var(--teamup-light-grey), var(--teamup-light-grey));
  background-size: 2.5rem 100%;
  background-position: right center;
  background-repeat: no-repeat;
  border: none;

  &__container {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    margin: -0.25rem;

    & > * {
      background-color: var(--teamup-light-grey);
      color: var(--teamup-white);
      font-size: var(--font-size--labeling_1);
      display: flex;
      align-items: center;
      border-radius: 1rem;
      padding: 0.25rem 0.5rem;
      margin: 0.25rem;
    }

    button {
      margin-left: 0.5rem;
      width: px2rem(15);
      height: px2rem(15);
      color: var(--teamup-white);
      border-radius: 100%;
      background: var(--teamup-blue-2) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/0.4em auto no-repeat;
      opacity: 1;
    }
  }

  &__add-item {
    background-color: transparent;
    border: none;
    color: var(--teamup-white);
    transform: translate(-0.1rem, 0.1rem);
  }
}