/* roboto-regular - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('fonts/roboto-v30-latin-regular.woff2') format('woff2'),
  url('fonts/roboto-v30-latin-regular.woff') format('woff');
}

/* roboto-italic - latin */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('fonts/roboto-v30-latin-italic.woff2') format('woff2'),
  url('fonts/roboto-v30-latin-italic.woff') format('woff');
}


/* lato-regular - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('fonts/lato-v23-latin-regular.woff2') format('woff2'),
  url('fonts/lato-v23-latin-regular.woff') format('woff');
}

/* lato-700 - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('fonts/lato-v23-latin-700.woff2') format('woff2'),
  url('fonts/lato-v23-latin-700.woff') format('woff');
}

$random: random();

@font-face {
  font-family: icomoon;
  src: url('fonts/icomoon.woff?#{$random}') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-size: var(--font-size--h1);
}

h2 {
  font-size: var(--font-size--h2);
  font-weight: 700;
}

h3 {
  font-size: var(--font-size--h3);
  font-weight: 700;
}

.title-screen-1 {
  font-size: var(--font-size--title-screen_1);
  font-weight: 700;
}

.title-screen-2 {
  font-size: var(--font-size--title-screen_2);
}

.instruction-exercise {
  font-size: var(--font-size--instruction);
}

.subtitle {
  font-size: var(--font-size--subtitle);
}

.labeling-1 {
  font-size: var(--font-size--labeling_1);
}

.labeling-2 {
  font-size: var(--font-size--labeling_2);
}