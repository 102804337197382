.teamup-icon-with-label {
  display: flex;
  align-items: center;
  color: var(--teamup-grey-2);
  font-size: var(--font-size--subtitle);
  margin-bottom: px2rem(15);

  &__text {
    margin-left: px2rem(15);
  }

  &.teamup-reset-filter {
    display: none;
    color: var(--teamup-blue-2);
    cursor: pointer;

    &.is-visible {
      display: flex;
    }
  }

  &--text-blue {
    color: var(--teamup-blue-2);

    [class^="icon-"] {
      color: var(--teamup-grey-2);
    }
  }

  &--small-text-space {
    .teamup-icon-with-label__text {
      margin-left: px2rem(10);
    }
  }
}

* + .teamup-icon-with-label {
  margin-top: px2rem(20);
}

.card-body--grid-3 {
  .teamup-icon-with-label {
    & + .teamup-icon-with-label {
      margin-top: 0;
      margin-left: px2rem(20);
    }
  }
}