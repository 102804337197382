.teamup-card-counter {
  h3 {
    font-size: var(--font-size--subtitle);
    margin-bottom: px2rem(17);

    & + * {
      display: flex;
      flex-wrap: wrap;
      margin: -1rem -.5rem;
    }
  }

  &__box {
    display: grid;
    align-items: flex-end;
    justify-content: center;
    margin: 1rem .5rem;

    & >* {
      grid-area: 1 / 1 / 2 / 2;
    }

    img {
      width: px2rem(75);
      height: px2rem(75);
      border-radius: px2rem(12);

      & + span {
        transform: translateY(px2rem(16.5));
        margin: 0 auto;
        width: px2rem(35);
        height: px2rem(35);
        border-radius: 100%;
        background-color: var(--teamup-white);
        border: 1px solid var(--teamup-blue);
        color: var(--teamup-blue);
        font-weight: bold;
        font-size: var(--font-size--title-screen_2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & + & {
    margin-top: px2rem(35);
  }

  & + .section-separator {
    margin-top: px2rem(40);
  }
}