.teamup-value-conflicts {
  .form-radio {
    padding-top: px2rem(20);
    padding-bottom: px2rem(20);
  }

  .card {
    &-body {
      padding-bottom: px2rem(40);
    }
  }
}