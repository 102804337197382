.form-check {
  &--as-button {
    padding: 0;

    .form-check-input {
      @include visually-hidden;

      & + label {
        display: flex;
        align-items: center;
        color: var(--teamup-blue);
        border: 1px solid var(--teamup-blue);
        border-radius: 2rem;
        background-color: transparent;
        padding: 0.375rem 1.25rem;
        font-weight: normal;
        margin: 0;
        font-size: var(--font-size--subtitle);
        cursor: pointer;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &::before,
        &::after {
          content: none;
        }
      }

      &:checked + label {
        background-color: var(--teamup-light-grey);
        border-color: var(--teamup-light-grey);
        color: var(--teamup-white);
      }
    }

    [class^="icon-"] {
      margin-right: px2rem(6.5);
      font-size: 0.65rem;
    }
  }
}
