.filter {
  &__label {
    display: block;
    color: var(--teamup-grey);
    letter-spacing: 0.0175rem;
    font-size: var(--font-size--subtitle);
    margin-bottom: 0.25rem;
  }

  &__input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: var(--teamup-light-grey);
    padding: 2px;
    border-radius: px2rem(24);

    & > * {
      font-size: var(--font-size--instruction);
      margin-bottom: 0;
      min-height: auto;
      padding-left: 0;
    }

    label {
      width: 100%;
      border-radius: px2rem(20);
      padding: 0.35rem 0.5rem;
      text-align: center;
      color: var(--teamup-white);
      cursor: pointer;
    }

    input {
      @include visually-hidden;

      &[type="radio"]:checked {
        & + label {
          color: var(--teamup-blue-2);
          background-color: var(--teamup-white-2);
        }
      }
    }
  }

  & + & {
    margin-top: px2rem(20);
  }

  &--3-cols {
    .filter__input {
      grid-template-columns: 1fr 1fr 1fr;
    }

    & + .filter-wrapper-elements {
      margin-top: px2rem(20);
    }
  }
}